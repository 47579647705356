import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import constants from '@/constants/static.json'
import { paginateArray } from '@/constants/utils'

export default function useUserList() {
  // Use toast
  const { PER_PAGE_OPTIONS, PER_PAGE } = constants
  const refUserListTable = ref(null)
  const toast = useToast()
  // Table Handlers
  const userTableColumns = [
    { key: 'Name', sortable: false },
    { key: 'Role', sortable: false },
    { key: 'LastLogin', sortable: false },
    { key: 'Actions', sortable: false },
  ]
  const perPage = ref(PER_PAGE)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const companyFilter = ref(null)
  const allUsers = ref([])
  const companies = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, companyFilter], () => {
    refetchData()
  })

  const filteredData = () => {
    let data = {}
    const queryLowered = searchQuery.value.toLowerCase()
    if (searchQuery.value !== '' && companyFilter.value !== null) {
      data = allUsers.value.filter(
        user => ((user.firstname.toLowerCase().includes(queryLowered) || user.lastname.toLowerCase().includes(queryLowered)) && user.companyID === companyFilter.value.companyID),
      )
    } else if (searchQuery.value === '' && companyFilter.value !== null) {
      data = allUsers.value.filter(
        user => (user.companyID === companyFilter.value.companyID),
      )
    } else if (searchQuery.value !== '' && companyFilter.value === null) {
      data = allUsers.value.filter(
        user => (user.firstname.toLowerCase().includes(queryLowered) || user.lastname.toLowerCase().includes(queryLowered)),
      )
    }
    return data
  }

  const fetchCompanies = () => {
    store.dispatch('app-game/fetchCompaniesAll')
      .then(response => {
        companies.value = response.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching company list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchUsers = (ctx, callback) => {
    let data = {}
    if (allUsers.value.length === 0) {
      fetchCompanies()
      store.dispatch('app-game/fetchUsers')
        .then(response => {
          allUsers.value = response.data.allResult
          totalUsers.value = allUsers.value.length
          data = paginateArray(allUsers.value, perPage.value, 1)
          callback(data)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    } else {
      if (searchQuery.value === '' && companyFilter.value === null) {
        data = paginateArray(allUsers.value, perPage.value, currentPage.value)
        totalUsers.value = allUsers.value.length
      } else {
        const filterData = filteredData()
        totalUsers.value = filterData.length
        data = paginateArray(filterData, perPage.value, currentPage.value)
      }
      callback(data)
    }
  }

  return {
    fetchUsers,
    userTableColumns,
    companies,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    companyFilter,
    refetchData,
  }
}

<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'user-configuration'}"
          >
            <span> {{ $t('ADD_USER') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
          <b-dropdown
            :text="$t('UPLOAD')"
            variant="gradient-primary"
            class="ml-1"
          >
            <b-dropdown-item @click="$router.push({ name: 'user-upload'})">
              <feather-icon icon="UploadIcon" />
              <span class="align-middle ml-50">{{ $t('UPLOAD_USER') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'user-game-upload'})">
              <feather-icon icon="UploadCloudIcon" />
              <span class="align-middle ml-50">{{ $t('UPLOAD_USER_GAME') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({ name: 'upload-list'})">
              <feather-icon icon="ListIcon" />
              <span class="align-middle ml-50">{{ $t('UPLOAD_LIST') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          :md="userData.userRoleId===userRole.ADMIN ? 3 : 6"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block mr-1"
            :placeholder="$t('SEARCH')"
          />
        </b-col>
        <b-col
          v-show="userData.userRoleId===userRole.ADMIN"
          cols="12"
          md="3"
        >
          <v-select
            id="id-company"
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('SELECT_COMPANY')"
            label="companyname"
            :options="companies"
          />
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refUserListTable"
      :items="fetchUsers"
      responsive
      :fields="userTableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!-- Column: Name -->
      <template #cell(Name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.firstname +' '+ data.item.lastname)"
              :variant="`light-${resolveUserRole(data.item.userRoleId).variant}`"
              :to="{ name: 'user-profile-id', params: { id: data.item.userID } }"
            />
          </template>
          <b-link
            :to="{ name: 'user-profile-id', params: { id: data.item.userID } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.firstname }}  {{ data.item.lastname }}
          </b-link>
          <small class="text-muted">{{ data.item.email }}</small>
        </b-media>
      </template>

      <!-- Column: Role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveUserRole(data.item.userRoleId).Icon"
            size="18"
            class="mr-50"
            :class="`text-${resolveUserRole(data.item.userRoleId).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ resolveUserRole(data.item.userRoleId).name }}</span>
        </div>
      </template>

      <!-- Column: Last Login -->
      <template #cell(LastLogin)="data">
        <div class="d-flex align-items-left text-nowrap">
          <small class="text-muted">{{ data.item.lastlogin ? formatDateTime(data.item.lastlogin) : $t('NEVER_LOGGED') }}</small>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.userID}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="$router.push({ name: 'user-configuration-id', params: { id: data.item.userID }})"
          />

          <feather-icon
            :id="`invoice-row-${data.item.userID}-send-icon`"
            icon="MailIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="openUserEmailModel(data.item)"
          />

          <feather-icon
            :id="`invoice-row-${data.item.userID}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.userID }})"
          />

          <feather-icon
            :id="`invoice-row-${data.item.userID}-preview-icon`"
            icon="UnlockIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'user-password-id', params: { id: data.item.userID }})"
          />

        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <user-email-model
      :view-user="viewUser"
    />
  </b-card>
</template>
<script>

import {
  BCard, BRow, BCol, BFormInput, BButton,
  BTable, BMedia, BAvatar, BLink,
  BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

// eslint-disable-next-line import/no-extraneous-dependencies
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import userStore from '@/store/user/userStore'
import useUserList from './useUserList'
// eslint-disable-next-line import/named
import { resolveUserRole, resolveUserStatusVariant } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import UserEmailModel from './UserEmailModel.vue'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    BAvatar,
    BLink,
    UserEmailModel,
    BDropdown,
    BDropdownItem,
  },
  mixins: [mixinDate],
  data() {
    return {
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      viewUser: {
        userID: '',
        firstname: '',
        lastname: '',
        nick: '',
        companyID: '',
        email: '',
        phonenumber: '',
        userRoleId: 0,
        about_me: '',
        send_daily_report: 0,
        send_settime_report: 0,
        is_disable: 0,
        lang: '',
      },
    }
  },
  methods: {
    openUserEmailModel(data) {
      if (data) {
        this.viewUser = { ...data }
      }
      this.$bvModal.show('user-email-modal')
    },
  },
  setup() {
    const { APP_STORE_MODULE } = constants

    // Register module
    if (!store.hasModule(APP_STORE_MODULE)) store.registerModule(APP_STORE_MODULE, userStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE)) store.unregisterModule(APP_STORE_MODULE)
    })

    const {
      fetchUsers,
      companies,
      userTableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      companyFilter,
      refetchData,
    } = useUserList()

    return {
      fetchUsers,
      companies,
      userTableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      companyFilter,
      refetchData,
      avatarText,
      resolveUserRole,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
